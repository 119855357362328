import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 76px;
`;

export const EmailLogo = () => (
  <Wrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 483.3 483.3">
      <path
        fill="white"
        width="20px"
        d="M424.3 57.75H59.1c-32.6 0-59.1 26.5-59.1 59.1v249.6c0 32.6 26.5 59.1 59.1 59.1h365.1c32.6 0 59.1-26.5 59.1-59.1v-249.5c.1-32.6-26.4-59.2-59-59.2zm32.1 308.7c0 17.7-14.4 32.1-32.1 32.1H59.1c-17.7 0-32.1-14.4-32.1-32.1v-249.5c0-17.7 14.4-32.1 32.1-32.1h365.1c17.7 0 32.1 14.4 32.1 32.1v249.5h.1z"
      />
      <path
        fill="white"
        d="M304.8 238.55l118.2-106c5.5-5 6-13.5 1-19.1-5-5.5-13.5-6-19.1-1l-163 146.3-31.8-28.4c-.1-.1-.2-.2-.2-.3-.7-.7-1.4-1.3-2.2-1.9L78.3 112.35c-5.6-5-14.1-4.5-19.1 1.1-5 5.6-4.5 14.1 1.1 19.1l119.6 106.9-119.1 111.5c-5.4 5.1-5.7 13.6-.6 19.1 2.7 2.8 6.3 4.3 9.9 4.3 3.3 0 6.6-1.2 9.2-3.6l120.9-113.1 32.8 29.3c2.6 2.3 5.8 3.4 9 3.4s6.5-1.2 9-3.5l33.7-30.2 120.2 114.2c2.6 2.5 6 3.7 9.3 3.7 3.6 0 7.1-1.4 9.8-4.2 5.1-5.4 4.9-14-.5-19.1l-118.7-112.7z"
      />
    </svg>

    {/*
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        d="M443.499 417.941l-181.333-128a10.667 10.667 0 00-12.309 0l-181.333 128a10.667 10.667 0 00-4.523 8.725v10.667C64 443.224 68.776 448 74.667 448h362.667c5.891 0 10.667-4.776 10.667-10.667v-10.667a10.671 10.671 0 00-4.502-8.725z"
        fill="#cfd8dc"
      />
      <path
        d="M262.165 289.941l-181.333-128c-4.807-3.405-11.465-2.268-14.87 2.539A10.658 10.658 0 0064 170.666v256c-.012 5.891 4.755 10.676 10.646 10.688a10.673 10.673 0 006.186-1.962l181.333-128c4.813-3.397 5.961-10.052 2.564-14.865a10.655 10.655 0 00-2.564-2.564v-.022z"
        fill="#fafafa"
      />
      <path
        d="M442.24 161.195a10.796 10.796 0 00-11.072.747l-181.333 128c-4.813 3.397-5.961 10.052-2.564 14.865a10.655 10.655 0 002.564 2.564l181.333 128c4.807 3.405 11.465 2.268 14.87-2.539a10.661 10.661 0 001.962-6.165v-256c0-3.986-2.221-7.639-5.76-9.472z"
        fill="#eee"
      />
      <path
        d="M468.8 71.339A10.666 10.666 0 00458.667 64H53.333c-5.891 0-10.667 4.776-10.667 10.667 0 3.357 1.581 6.519 4.267 8.533L249.6 232.533a10.669 10.669 0 0012.651 0L464.917 83.2a10.666 10.666 0 003.883-11.861z"
        fill="#eceff1"
      />
      <path
        d="M458.667 64a10.67 10.67 0 00-6.4 2.133L256 210.752 59.733 66.133a10.67 10.67 0 00-6.4-2.133C23.878 64 0 87.878 0 117.333v277.333C0 424.122 23.878 448 53.333 448h21.333c5.891 0 10.667-4.776 10.667-10.667v-246.08l164.501 116.139a10.667 10.667 0 0012.309 0l164.523-116.139v246.08c0 5.891 4.776 10.667 10.667 10.667h21.333C488.122 448 512 424.122 512 394.667V117.333C512 87.878 488.122 64 458.667 64z"
        fill="#f44336"
      />
    </svg> */}
  </Wrapper>
);
