import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 64px;
`;

export const InstaLogo = () => (
  <Wrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="-46.004"
        y1="634.121"
        x2="-32.933"
        y2="647.192"
        gradientTransform="matrix(32 0 0 -32 1519 20757)"
      >
        <stop offset="0" stopColor="#ffc107" />
        <stop offset=".507" stopColor="#f44336" />
        <stop offset=".99" stopColor="#9c27b0" />
      </linearGradient>
      <path
        d="M352 0H160C71.648 0 0 71.648 0 160v192c0 88.352 71.648 160 160 160h192c88.352 0 160-71.648 160-160V160C512 71.648 440.352 0 352 0zm112 352c0 61.76-50.24 112-112 112H160c-61.76 0-112-50.24-112-112V160C48 98.24 98.24 48 160 48h192c61.76 0 112 50.24 112 112v192z"
        fill="url(#a)"
      />
      <linearGradient
        id="b"
        gradientUnits="userSpaceOnUse"
        x1="-42.297"
        y1="637.828"
        x2="-36.64"
        y2="643.485"
        gradientTransform="matrix(32 0 0 -32 1519 20757)"
      >
        <stop offset="0" stopColor="#ffc107" />
        <stop offset=".507" stopColor="#f44336" />
        <stop offset=".99" stopColor="#9c27b0" />
      </linearGradient>
      <path
        d="M256 128c-70.688 0-128 57.312-128 128s57.312 128 128 128 128-57.312 128-128-57.312-128-128-128zm0 208c-44.096 0-80-35.904-80-80 0-44.128 35.904-80 80-80s80 35.872 80 80c0 44.096-35.904 80-80 80z"
        fill="url(#b)"
      />
      <linearGradient
        id="c"
        gradientUnits="userSpaceOnUse"
        x1="-35.546"
        y1="644.579"
        x2="-34.792"
        y2="645.333"
        gradientTransform="matrix(32 0 0 -32 1519 20757)"
      >
        <stop offset="0" stopColor="#ffc107" />
        <stop offset=".507" stopColor="#f44336" />
        <stop offset=".99" stopColor="#9c27b0" />
      </linearGradient>
      <circle cx="393.6" cy="118.4" r="17.056" fill="url(#c)" />
    </svg>
  </Wrapper>
);
